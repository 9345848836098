<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-calendar"/>
      <h5 class="d-inline ml-2">
        {{ this.$route.meta.label }}
        <template v-if="restaurant && !loading">
          : <h3 class="d-inline"><CBadge color="secondary">{{ restaurant.restaurant_name }}</CBadge></h3>
        </template>
      </h5>

      <div class="card-header-actions">
        <CButton color="dark" :to="{ name: 'View Restaurant', params: { id: itemId } }">
          <CIcon name="cil-arrow-thick-left" class="align-bottom" /> Back
        </CButton>
      </div>
    </CCardHeader>

    <CCardBody v-if="restaurant">
      <custom-opening-times
        :restaurantId="restaurant.id"
        :countryId="restaurant.countryId"
      />
    </CCardBody>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="'1rem'" />
  </CCard>
</template>

<script>
import CustomOpeningTimes from './CustomTimes'

export default {
  name: 'RestaurantOpeningTimes',
  components: {
    'custom-opening-times': CustomOpeningTimes
  },

  data() {
    return {
      restaurant: null,
      loading: false,
    }
  },

  async mounted() {
    this.itemId && await this.getRestaurant(this.itemId)
  },

  computed: {
    itemId() { return Number(this.$route.params.restaurant_id) || null },
  },

  methods: {
    async getRestaurant() {
      this.loading = true;
      await this.$axios
        .get(this.$backend.RESTAURANT.GET_NAME.replace("{id}", this.itemId), {
          params: {
            fields: 'id,open_monday,open_tuesday,open_wednesday,open_thursday,open_friday,open_saturday,open_sunday,deliver_monday,deliver_tuesday,deliver_wednesday,deliver_thursday,deliver_friday,deliver_saturday,deliver_sunday,countryId',
            relationships: null,
          }
        })
        .then(({data}) => {
          this.restaurant = data;
          this.loading = false;
        })
        .catch((error) => {
          this.$toast.error(`${error.response.data.message}`);
          setTimeout(() => { this.$router.push({ name: 'View Restaurant', params: { id: this.itemId } }) }, 3000);
        })
    },
  }
}
</script>

<style>
.tab-menu > div:first-child {
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  /* border-bottom: 1px solid #d8dbe0; */
}
</style>
