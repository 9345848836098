<template>
  <CCardBody class="custom-times p-0">

    <!-- Add / Edit custom times -->
    <CCard class="add-custom-times shadow">
      <CCardHeader role="button" class="d-flex align-items-center justify-content-between border-0 text-success px-0"
        @click="() => this.$store.commit('toggle', 'addCustomTimes')">
        <h5 class="d-flex flex-gap-1 align-items-center m-0">
          <CIcon :name="`cil-${$store.state.addCustomTimes ? 'chevron-bottom' : 'plus'}`" />
          {{ form.id ? 'Edit' : 'Add' }} custom hours
        </h5>
      </CCardHeader>

      <CCollapse :show="$store.state.addCustomTimes">
        <CCardBody class="p-2">

          <div class="d-flex">
            <div class="form-group date-picker">
              <label class="d-block">Day / Holiday:</label>
              <v-date-picker
                mode="date"
                v-model="pickedDate"
                :disabled-dates="{
                  end: new Date().setDate(new Date().getDate() - 1),
                }"
                :attributes="datePickerAttributes"
              >
                <template v-slot="{ inputValue, inputEvents }">
                  <input
                    class="form-control border px-2 py-1"
                    :class="{'is-invalid': form.custom_date && !isCustomDateValid}"
                    :value="inputValue"
                    v-on="inputEvents"
                  />
                </template>
              </v-date-picker>

              <small class="text-info text-info">
                {{ findHolidayByDate(pickedDate)?.holiday_name?.name }}
              </small>
            </div>
          </div>

          <!-- <div class="d-flex">
            <CInput
              type="date"
              label="Day"
              :value.sync="$v.form.custom_date.$model"
              :min="new Date().toISOString().slice(0,10)"
              :isValid="checkIfValid('custom_date')"
            />
          </div> -->

          <CCollapse :show="isCustomDateValid">
            <CButton class="px-0" color="link" @click="applyPickupToDelivery">
              <span v-if="isApplied">
                <CIcon name="cil-check-alt" class="mr-1" />Applied!
              </span>
              <span v-else>
                <CIcon name="cil-copy" class="mr-1" />Apply Pickup to Delivery
              </span>
            </CButton>

            <restaurant-times @isValid="(value) => form.isValid = value" day='Pickup'
              :time.sync="$v.form.pickup_times.$model" />
            <restaurant-times @isValid="(value) => form.isValid = value" day='Delivery'
              :time.sync="$v.form.delivery_times.$model" />
          </CCollapse>

          <div class="d-flex mt-3">
            <CButton color="link" class="pl-0" @click="cancelEdit">Cancel</CButton>
            <CButton color="success" shape="pill" :disabled="!isCustomDateValid" @click="confirm">Confirm</CButton>
          </div>
        </CCardBody>
      </CCollapse>

      <CElementCover :opacity="0.4" v-show="submitted"/>
    </CCard>

    <CDataTable striped hover
      :items="loadedItems"
      :fields="fields"
      :column-filter="{ external: true, lazy: true }"
      :sorter="{ external: true, resetable: true }"
      :loading="loading"
      :noItemsView="{
        noResults: 'No filtering results are available!',
        noItems: 'No custom opening times found!',
      }"
      @update:sorter-value="sorterValue"
    >
      <!-- Custom date filter -->
      <template #custom_date-filter>
        <input type="date" v-model="customDate" />
      </template>

      <!-- Custom date -->
      <template #custom_date="{item}">
        <td>
          <strong>{{ item.custom_date }}</strong>
          <cite v-if="item.holiday" class="text-info ml-3"> {{ item.holiday?.holiday_name?.name }}</cite>
        </td>
      </template>

      <!-- Type -->
      <template #type="{item}">
        <td>
          {{ item.type | ucfirst }}
        </td>
      </template>

      <!-- Pickup times -->
      <template #pickup_times="{item}">
        <td>
          <template v-if="item.pickup_times">
            <strong class="border border-dark p-1 mr-1" v-for="(times, index) in item.pickup_times.split(',')">
              {{ times }}
            </strong>
          </template>

          <span v-else class="closed d-flex align-items-center flex-gap-1">
            <span class="dot bg-danger"></span> Closed all day
          </span>
        </td>
      </template>

      <!-- Delivery times -->
      <template #delivery_times="{item}">
        <td>
          <template v-if="item.delivery_times">
            <strong class="border border-dark p-1 mr-1" v-for="(times, index) in item.delivery_times.split(',')">
              {{ times }}
            </strong>
          </template>

          <span v-else class="closed d-flex align-items-center flex-gap-1">
            <span class="dot bg-danger"></span> Closed all day
          </span>
        </td>
      </template>

      <!-- Actions -->
      <template #actions="{ item, index }">
        <td class="py-2 text-center">
          <CButton v-if="new Date(item.custom_date) >= new Date()" size="sm" color="info" variant="ghost" shape="pill" @click="editItem(item, index)">
            <CIcon name="cil-align-left" />
          </CButton>

          <CSpinner v-if="item.deleting" size="sm" color="danger" />
          <CButton v-else size="sm" color="danger" variant="ghost" shape="pill" @click="deleteItem(item, index)">
            <CIcon name="cil-trash" />
          </CButton>
        </td>
      </template>
    </CDataTable>

    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </CCardBody>
</template>

<script>
import { mapGetters } from "vuex";
import _debounce from 'lodash/debounce';
import { DatePicker } from 'v-calendar'
import { validationMixin } from "vuelidate"
import { required } from "vuelidate/lib/validators"
import RestaurantTimes from '@/components/RestaurantTimes'

export default {
  name: "CustomOpeningTimes",
  props: {
    restaurantId: { default: null },
    countryId: { default: 1 },
  },
  components: {
    'v-date-picker': DatePicker,
    RestaurantTimes,
  },
  data() {
    return {
      holidays: [],
      loadedItems: [],
      fields: [
        { key: "actions", label: "", sorter: false, filter: false, _style: "min-width: 84px; width: 84px; text-align: center;" },
        { key: "custom_date", label: "Date", sorter: true, filter: true, _style: "min-width: 200px; width: 300px;" },
        { key: "pickup_times", sorter: false, filter: false, _style: "min-width: 200px;" },
        { key: "delivery_times", sorter: false, filter: false, _style: "min-width: 200px;" },
      ],

      total: 0,
      orderBy: null,
      direction: null,

      loading: false,
      submitted: false,

      // Date filter
      customDate: null,

      // Add new
      form: {},
      isApplied: false,
    };
  },

  async beforeMount() {
    this.filterByDate = _debounce(async () => {
      await this.getAllItems();
    }, 1000);

    this.getTimesByDate = _debounce(async () => {
      this.form = {
        ...this.form,
        ...{ id: null, pickup_times: null, delivery_times: null, isValid: null },
      };

      const item = this.loadedItems.find((i) => i.custom_date == this.form.custom_date);
      if (item) {
        this.form.id = item.id;
        this.form.pickup_times = item.pickup_times;
        this.form.delivery_times = item.delivery_times;
      }
    }, 500);
  },

  async mounted() {
    this.loading = true;
    await this.getHolidays();
    await this.getAllItems();
    this.loading = false;

    this.getForm();
    this.$watch('form.custom_date', { handler: 'getTimesByDate' });
  },

  watch: {
    customDate() {
      this.filterByDate();
    },
  },

  computed: {
    ...mapGetters(["errors"]),
    isValid() { return !this.$v.form.$invalid },
    isDirty() { return this.$v.form.$anyDirty },
    pickedDate: {
      get: function () { return this.form.custom_date ? new Date(this.form.custom_date) : null; },
      set: function (newValue) {
        this.form.custom_date = this.moment(newValue).format("YYYY-MM-DD")
      }
    },
    isCustomDateValid() { return this.form.custom_date && !(isNaN(this.pickedDate)) },
    datePickerAttributes() {
      return [
        ...this.holidays.map((el, index) => {
          return {
            key: 'holiday_' + index,
            highlight: {
              color: 'teal',
              fillMode: 'light',
            },
            dot: true,
            popover: {
              label: el.holiday_name?.name,
            },
            dates: { start: new Date(el.start_date), end: new Date(el.end_date || el.start_date) },
            order: 0
          };
        }),
        ...this.loadedItems.map((el, index) => {
          return {
            key: 'cot' + index,
            highlight: {
              color: 'orange',
              fillMode: 'light',
            },
            dates: { start: new Date(el.custom_date), end: new Date(el.custom_date) },
          };
        })
      ]
    }
  },

  mixins: [validationMixin],

  validations: {
    form: {
      custom_date: {
        required,
        minDate: function () {
          const currentDate = new Date();
          currentDate.setUTCHours(0, 0, 0, 0);

          return new Date(this.form.custom_date) >= currentDate;
        }
      },
      pickup_times: { },
      delivery_times: { },
    }
  },

  methods: {
    async getHolidays() {
      await this.$axios
        .get(this.$backend.HOLIDAYS.GET_ALL, {
          params: {
            country: this.countryId,
          }
        })
        .then(({ data }) => {
          this.holidays = data;
        })
        .catch((error) => {
          this.holidays = [];
        })
    },
    async getAllItems() {
      var url = new URL(this.$backend.RESTAURANT.OPENING_TIMES.GET_ALL.replace("{restaurant_id}", this.restaurantId)),
        params = {
          'order-by': this.orderBy,
          direction: this.direction,
          date: this.customDate,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      await this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          this.total = items.length;
          this.loadedItems = items.map((el) => {
            return {
              ...el,
              deleting: false,
            };
          });
        });
    },
    findHolidayByDate(targetDate) {
      const matchingHoliday = this.holidays.find(holiday => {
        const startDate = new Date(holiday.start_date);
        const endDate = holiday.end_date ? new Date(holiday.end_date) : startDate;

        return startDate <= targetDate && targetDate <= endDate;
      });

      return matchingHoliday || null;
    },

    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },
    applyPickupToDelivery() {
      this.form.delivery_times = this.form.pickup_times;
      this.isApplied = true;
      setTimeout(() => this.isApplied = false, 3000);
    },


    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },
    validate() {
      this.$v.$touch()
    },
    getForm() {
      this.form = {
        id: null,
        custom_date: null,
        pickup_times: null,
        delivery_times: null,

        isValid: true,
      }
    },
    editItem(item, index) {
      this.$store.state.addCustomTimes = true;
      this.form.id = item.id;
      this.form.custom_date = item.custom_date;
    },
    cancelEdit() {
      this.$store.state.addCustomTimes = false;
      this.submitted = false

      setTimeout(() => {
        this.getForm();
        this.$v.$reset()
      }, 500)
    },

    async confirm() {
      if (!(this.isValid && this.isCustomDateValid && this.form.isValid)) {
        this.validate();
        return;
      }

      if (this.form.id) {
        await this.update()
      } else {
        await this.store()
      }
    },

    async update() {
      this.submitted = true

      await this.$axios.put(this.$backend.RESTAURANT.OPENING_TIMES.UPDATE.replace("{restaurant_id}", this.restaurantId).replace("{id}", this.form.id), this.form)
        .then(({ data }) => {
          this.$toast.success(`${data.message}`);
          this.getAllItems();
          this.cancelEdit();

          // let item = this.loadedItems.find(item => item.id === data.opening_time.id);
          // if (item) {
          //   item.pickup_times = data.opening_time.pickup_times;
          //   item.delivery_times = data.opening_time.delivery_times;
          // }
        })
        .catch((error) => {
          this.submitted = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        });
    },
    async store() {
      this.submitted = true

      await this.$axios.post(this.$backend.RESTAURANT.OPENING_TIMES.STORE.replace("{restaurant_id}", this.restaurantId), this.form)
        .then(({ data }) => {
          this.$toast.success(`${data.message}`);
          this.getAllItems();
          this.cancelEdit();

          // this.loadedItems.length == 0
          //   ? this.getAllItems()
          //   : this.loadedItems.push(data.opening_time);

          // this.loadedItems = this.loadedItems.slice().sort((a, b) => new Date(b.custom_date) - new Date(a.custom_date));
        })
        .catch((error) => {
          this.submitted = false
          this.$toast.error(typeof this.errors === 'object'
            ? Object.values(this.errors).join()
            : this.errors
          );
        });
    },
    async deleteItem(item, index) {
      const confirm = await this.mSwal.fire({
        title: "Are you sure you want to delete?",
        text: "The custom time will be deleted!",
        icon: "warning",
      });

      if (confirm.isConfirmed) {
        this.cancelEdit();
        item.deleting = true;
        const deleteUrl = this.$backend.RESTAURANT.OPENING_TIMES.DELETE.replace("{restaurant_id}", this.restaurantId).replace("{id}", item.id);

        try {
          const { data } = await this.$axios.delete(deleteUrl);
          index !== -1 && this.$delete(this.loadedItems, index);
          this.$toast.success(data.message);
        } catch (error) {
          item.deleting = false;

          if (error.response) {
            this.$toast.error(`${error.response.data.message}`);
          } else if (error.request) {
            this.$toast.error('No response received from the server.');
          } else {
            this.$toast.error('An unexpected error occurred.');
          }
        } finally {
          this.$forceUpdate();
        }
      }
    },
  },
};
</script>

<style>
.custom-times th {
  border-top: none;
}
.custom-times th, .custom-times td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}
.custom-times .form-control,
.custom-times .date-picker span>input,
.custom-times span.closed {
  font-size: 1.125rem;
  color: #9b0a0a;
  font-weight: 500;
}
.custom-times span.closed {
  font-size: 1rem;
}

.custom-times .date-picker span>input {
  border: none;
  width: 95%;
}
.custom-times .date-picker .form-control {
  height: inherit !important;
  padding: 0;
}

.add-custom-times.card {
  padding: 0.5rem !important;
  border: 1px solid rgba(0, 0, 21, 0.125) !important;
  border-radius: 0.25rem;
}
</style>
